import React, { useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Icon, InlineIcon } from "@iconify/react";
import questionCircleOutlined from "@iconify-icons/ant-design/question-circle-outlined";
import Lifestyle from "./lifestyle";
import Loan from "./PayoffLoan";
import Baseline from "./baseline";
import Purchase from "./purchase";
import Results from "./results";
class Goals extends React.Component {
  constructor(props) {
    super();
    this.state = {
      toHaveYear: "10",
      toSaveTarget: "100,000",
      toHaveTarget: "100,000",
      toHaveClicked: false,
      life: true,
      base: false,
      purchase: false,
      risk: false,
      order: ["life", "base", "purchase", "risk"],
      goals: {
        checked: true,
        monthlySpend: "500",
        monthlySavings: "1,000",
        mortgage: "1,000",
        monthlyIncome: "2,500",
        investmentInterestRate: "8.0",
        incomeIncreases: [{ amount: "500", yearsAway: "4" }],
      },
      baseline: {
        assets: "10,000",
        debts: [{ amount: "20,000", rate: "4.0" }],
        house: {
          monthlyCost: "1,500",
          appreciationRate: "4.0",
          loanAmount: "200,000",
          interestRate: "3.2",
          value: "500,000",
          yearsAway: -1,
        },
        roi: "8.0",
      },
      purchases: { purchases: [{ amount: "20,000", years: "4" }], houses: [] },
      riskTolerence: {},
    };
  }

  handleChange = (event, newVal, other) => {
    var num = newVal.target.value.replace(/[^0-9]/g, "").replace(/^0+/, "");
    if (num.length === 0) {
      num = "0";
    }
    this.setState({
      [event]: num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    });
  };

  toHave = () => {
    this.setState({
      toHaveClicked: !this.state.toHaveClicked,
    });
  };
  toSave = () => {};
  handleButtonOpen = (event, newVal, other) => {
    this.setState({
      life: event === "life" ? !this.state.life : false,
      base: event === "base" ? !this.state.base : false,
      purchase: event === "purchase" ? !this.state.purchase : false,
      risk: event === "risk" ? !this.state.risk : false,
    });
  };
  goalsChange = (property, value) => {
    var dummy = this.state.goals;
    dummy[property] = value;
    this.setState({ goals: dummy });
  };
  baseChange = (property, value) => {
    var dummy = this.state.baseline;
    dummy[property] = value;
    this.setState({ baseline: dummy });
  };

  purchaseChange = (property, value) => {
    var dummy = this.state.purchases;
    dummy[property] = value;
    this.setState({ purchases: dummy });
  };
  render() {
    return (
      <div className="sliders_box">
        <div className="blockTitle">
          <div className="header">Goals</div>
        </div>
        <Icon icon={questionCircleOutlined} data-tip data-for="registerTip90" />
        <ReactTooltip id="registerTip90" place="right" effect="solid">
          Goals helps you asses financial goals and make lifestyle adjustments
          to better meet those goals.
        </ReactTooltip>
        <p></p>
        <div className="goalsCard card">
          <div className="header">
            I want to have $
            <input
              className="goalsBox"
              onkeypress={
                (this.width2 =
                  (this.state.toHaveTarget.toLocaleString().length + 1) * 15 +
                  "px")
              }
              style={{ width: this.width2 }}
              value={this.state.toHaveTarget.toLocaleString()}
              onChange={this.handleChange.bind(this, "toHaveTarget")}
            ></input>{" "}
            in{" "}
            <input
              className="goalsBox"
              onkeypress={
                (this.width3 =
                  (this.state.toHaveYear.toLocaleString().length + 1) * 15 +
                  "px")
              }
              style={{ width: this.width3 }}
              value={this.state.toHaveYear}
              onChange={this.handleChange.bind(this, "toHaveYear")}
            ></input>{" "}
            years
          </div>
          <div
            className={
              this.state.toHaveClicked ? "toHaveBodyActive" : "toHaveBody"
            }
          >
            <div
              className="card-container"
              style={
                this.state.life
                  ? { boxShadow: "0px 0px 0px 0px" }
                  : {
                      height: "85px",
                      width: "85px",
                    }
              }
              onClick={this.handleButtonOpen.bind(this, "life")}
            >
              <div className="insideGoalCard">Lifestyle</div>
            </div>
            <div
              className="card-container"
              style={
                this.state.base
                  ? { boxShadow: "0px 0px 0px 0px" }
                  : {
                      height: "85px",
                      width: "85px",
                    }
              }
              onClick={this.handleButtonOpen.bind(this, "base")}
            >
              <div className="insideGoalCard">Baseline</div>
            </div>

            <div
              className="card-container"
              style={
                this.state.purchase
                  ? { boxShadow: "0px 0px 0px 0px" }
                  : {
                      height: "85px",
                      width: "85px",
                    }
              }
              onClick={this.handleButtonOpen.bind(this, "purchase")}
            >
              <div className="insideGoalCard">Future Big Purchases</div>
            </div>

            <div
              className="card-container last"
              style={
                this.state.risk
                  ? { boxShadow: "0px 0px 0px 0px" }
                  : {
                      height: "85px",
                      width: "85px",
                    }
              }
              onClick={this.handleButtonOpen.bind(this, "risk")}
            >
              <div className="insideGoalCard">Financial Results</div>
            </div>
            <hr></hr>
            {this.state.life && this.state.toHaveClicked ? (
              <Lifestyle
                value={this.state.goals}
                onChange={this.goalsChange}
              ></Lifestyle>
            ) : (
              ""
            )}
            {this.state.base && this.state.toHaveClicked ? (
              <Baseline
                value={this.state.baseline}
                onChange={this.baseChange}
              ></Baseline>
            ) : (
              ""
            )}
            {this.state.purchase && this.state.toHaveClicked ? (
              <Purchase
                value={this.state.purchases}
                onChange={this.purchaseChange}
              ></Purchase>
            ) : (
              ""
            )}
            {this.state.risk && this.state.toHaveClicked ? (
              <Results
                goals={this.state.goals}
                baseline={this.state.baseline}
                purchase={this.state.purchases}
                toHaveYear={this.state.toHaveYear}
                toHaveTarget={this.state.toHaveTarget}
              ></Results>
            ) : (
              ""
            )}
            {this.state.risk ||
            this.state.base ||
            this.state.life ||
            this.state.purchase ? (
              <button
                className="makeAPlanButton planButtonSize"
                onClick={() => {
                  this.setState({
                    life: false,
                    base: this.state.life ? true : false,
                    purchase: this.state.base ? true : false,
                    risk: this.state.purchase ? true : false,
                  });
                }}
              >
                <div>Next</div>
              </button>
            ) : (
              ""
            )}
          </div>

          <div>
            <button
              className="makeAPlanButton planButtonSize"
              onClick={this.toHave}
            >
              <div>
                {!this.state.toHaveClicked ? "Make a Plan" : "Minimize"}
              </div>
            </button>
          </div>
        </div>
        <p></p>
      </div>
    );
  }
}

export default Goals;
