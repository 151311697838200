import React from "react";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Icon, InlineIcon } from "@iconify/react";
import questionCircleOutlined from "@iconify-icons/ant-design/question-circle-outlined";

class Loan extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "rgb(0,0,0)",
          fontSize: 20,
        },
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              label: "Dollars",
              ticks: {
                beginAtZero: true,
                userCallback: function (value, index, values) {
                  return value.toLocaleString(); // this is all we need
                },
              },
            },
          ],
        },
      },
      downPayment: "20,000",
      minDownPayment: "5,000",
      minMonthlyPayment: "500",
      monthlyPayment: "1,000",
      owed: "100,000",
      intrestRate: "4.0",
      investedCapitalReturnRate: "8.0",
      timelineLength: 0,
      additionArray: [],

      data: {
        labels: [],
        datasets: [
          {
            label: "Min Payments Loan Amount Remaining",
            fill: false,
            backgroundColor: "rgba(0,0,0,1)",
            borderColor: "rgba(0,0,0,1)",
            data: [12],
          },
          {
            label: "Max Payments Loan Amount Remaining",
            fill: false,
            backgroundColor: "rgba(255,0,0,1)",
            borderColor: "rgba(255,0,0,1)",
            data: [12],
          },
          {
            label: "Min Payments Investments Value",
            fill: false,
            backgroundColor: "rgba(0,100,0,1)",
            borderColor: "rgba(0,100,0,1)",
            data: [12],
          },
          {
            label: "Max Payments Investments Value",
            fill: false,
            backgroundColor: "rgba(255,100,0,1)",
            borderColor: "rgba(255,100,0,1)",
            data: [12],
          },
        ],
      },
    };
    this.updateGraph();
  }

  handleChange = (event, newValue, other) => {
    this.setState({ [event]: other });
    this.updateGraph();
  };
  updateGraph = () => {
    var minLoan = [];
    var maxLoan = [];
    var investedHigh = [];
    var investedLow = [];
    var invsetmentsLow = 0;
    var invsetmentsHigh = 0;
    var intrest = parseFloat(this.state.intrestRate) / 100.0 + 1.0;

    var i = 0;
    var owedLow =
      parseFloat(this.state.owed.replaceAll(",", "")) -
      parseFloat(this.state.minDownPayment.replaceAll(",", ""));
    minLoan.push(owedLow);

    var owedHigh =
      parseFloat(this.state.owed.replaceAll(",", "")) -
      parseFloat(this.state.downPayment.replaceAll(",", ""));

    maxLoan.push(owedHigh);
    var paidLow = parseFloat(this.state.minMonthlyPayment.replaceAll(",", ""));
    var paidHigh = parseFloat(this.state.monthlyPayment.replaceAll(",", ""));
    var labels = [];
    var roic = parseFloat(this.state.investedCapitalReturnRate) / 100.0 + 1.0;
    investedHigh.push(0);
    investedLow.push(
      parseFloat(this.state.downPayment.replaceAll(",", "")) -
        parseFloat(this.state.minDownPayment.replaceAll(",", ""))
    );
    invsetmentsLow = investedLow[0];
    while (owedLow > 0 && i < 60) {
      owedLow = owedLow * intrest - paidLow * 12;
      owedHigh = owedHigh * intrest - paidHigh * 12;

      invsetmentsLow = (paidHigh - paidLow) * 12 + invsetmentsLow * roic;
      if (owedLow < 0) {
        minLoan.push(0);
        investedLow.push(invsetmentsLow);
      } else {
        investedLow.push(invsetmentsLow);
        minLoan.push(owedLow);
      }

      if (owedHigh < 0) {
        maxLoan.push(0);
        invsetmentsHigh = paidHigh * 12 + invsetmentsHigh * roic;
      } else {
        invsetmentsHigh = 0;
        maxLoan.push(owedHigh);
      }
      investedHigh.push(invsetmentsHigh);

      labels.push(i);
      i++;
    }
    labels.push(i);

    var holder = this.state.data;

    holder.datasets[0].data = minLoan;
    holder.datasets[1].data = maxLoan;
    holder.datasets[2].data = investedHigh;
    holder.datasets[3].data = investedLow;
    holder.labels = labels;
    console.log(holder);
    this.setState({ data: holder });
  };

  remove = (index) => {
    console.log(index);
  };
  render() {
    var thisyear = new Date().getFullYear();
    return (
      <div className="sliders_box">
        <div className="blockTitle">
          <div className="header">Payoff Loan or Invest</div>
        </div>
        <Icon icon={questionCircleOutlined} data-tip data-for="registerTip91" />
        <ReactTooltip id="registerTip91" place="right" effect="solid">
          Payoff Loan or Invest considers if it is better to pay off a loan as
          fast or as slow as possible. It assumes you invest the difference
          between the most you can afford and the least. In this example the
          person wants to take out a loan for 100k and can put at most 20k down
          or can put as little as 5k down. They also can afford to pay as high
          as 1k monthly on their loan and have a min payment of 500. This
          calculator charts out what their investments would look like assuming
          if they took the 5k down and 500 a month option they invested the rest
          and assuming if they took the 20k down and 1k a month once their loan
          was gone they began to invest that 1k.
        </ReactTooltip>
        <p></p>
        <div className="block">
          <div data-tip data-for="registerTip" className="joint_input_title">
            Min Down Payment
          </div>
          <ReactTooltip id="registerTip" place="right" effect="solid">
            Minimum down payment possible
          </ReactTooltip>
          <div>
            <input
              value={this.state.minDownPayment}
              onChange={(event) => {
                var tempVal = event.target.value;
                tempVal = tempVal
                  .replace(/[^\d.-]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .replace(/^0+/, "");
                if (tempVal.length === 0) {
                  tempVal = "0";
                }
                this.setState(
                  {
                    minDownPayment: tempVal,
                  },
                  () => {
                    this.updateGraph();
                  }
                );
              }}
            />
          </div>
        </div>
        <div className="smallblock">-</div>
        <div className="block">
          <div data-tip data-for="registerTip2" className="joint_input_title">
            Max Down Payment
          </div>
          <ReactTooltip id="registerTip2" place="right" effect="solid">
            Maximum down payment possible
          </ReactTooltip>
          <div>
            <input
              value={this.state.downPayment}
              onChange={(event) => {
                var tempVal = event.target.value;
                tempVal = tempVal
                  .replace(/[^\d.-]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .replace(/^0+/, "");
                if (tempVal.length === 0) {
                  tempVal = "0";
                }
                console.log(tempVal);
                this.setState(
                  {
                    downPayment: tempVal,
                  },
                  () => {
                    this.updateGraph();
                  }
                );
              }}
            />
          </div>
        </div>
        <div />

        <div className="block">
          <div data-tip data-for="registerTip3" className="joint_input_title">
            Min Monthly Payment
          </div>
          <ReactTooltip id="registerTip3" place="right" effect="solid">
            Minimum monthly payment possible
          </ReactTooltip>
          <div>
            <input
              value={this.state.minMonthlyPayment}
              onChange={(event) => {
                var tempVal = event.target.value;
                tempVal = tempVal
                  .replace(/[^\d.-]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .replace(/^0+/, "");
                if (tempVal.length === 0) {
                  tempVal = "0";
                }
                console.log(tempVal);
                this.setState(
                  {
                    minMonthlyPayment: tempVal,
                  },
                  () => {
                    this.updateGraph();
                  }
                );
              }}
            />
          </div>
        </div>
        <div className="smallblock">-</div>
        <div className="block">
          <div data-tip data-for="registerTip4" className="joint_input_title">
            Max Monthly Payment
          </div>
          <ReactTooltip id="registerTip4" place="right" effect="solid">
            Maximum monthly payment possible
          </ReactTooltip>
          <div>
            <input
              value={this.state.monthlyPayment}
              onChange={(event) => {
                var tempVal = event.target.value;
                tempVal = tempVal
                  .replace(/[^\d.-]/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  .replace(/^0+/, "");
                if (tempVal.length === 0) {
                  tempVal = "0";
                }
                console.log(tempVal);
                this.setState(
                  {
                    monthlyPayment: tempVal,
                  },
                  () => {
                    this.updateGraph();
                  }
                );
              }}
            />
          </div>
        </div>
        <div data-tip data-for="registerTip5" className="slider_title">
          Loan Interest Rate
        </div>
        <ReactTooltip id="registerTip5" place="right" effect="solid">
          Interest rate of the loan
        </ReactTooltip>
        <div>
          <input
            value={this.state.intrestRate}
            onChange={(event) => {
              var tempVal = event.target.value;
              tempVal = tempVal
                .replace(/[^\d.-]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .replace(/^0+/, "");
              if (tempVal.length === 0) {
                tempVal = "0";
              }
              this.setState(
                {
                  intrestRate: tempVal,
                },
                () => {
                  this.updateGraph();
                }
              );
            }}
          />
        </div>
        <div data-tip data-for="registerTip6" className="slider_title">
          Total Amount Owed
        </div>
        <ReactTooltip id="registerTip6" place="right" effect="solid">
          Total amount owed, includes down payment
        </ReactTooltip>
        <div>
          <input
            value={this.state.owed}
            onChange={(event) => {
              var tempVal = event.target.value;
              tempVal = tempVal
                .replace(/[^\d.-]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .replace(/^0+/, "");
              if (tempVal.length === 0) {
                tempVal = "0";
              }
              console.log(tempVal);
              this.setState(
                {
                  owed: tempVal,
                },
                () => {
                  this.updateGraph();
                }
              );
            }}
          />
        </div>
        <hr />
        <div data-tip data-for="registerTip7" className="slider_title">
          Invested Capital Expected Return Rate
        </div>

        <ReactTooltip id="registerTip7" place="right" effect="solid">
          Expected annual percentage return of investments
        </ReactTooltip>
        <div>
          <input
            value={this.state.investedCapitalReturnRate}
            onChange={(event) => {
              var tempVal = event.target.value;
              tempVal = tempVal
                .replace(/[^\d.-]/g, "")
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                .replace(/^0+/, "");
              if (tempVal.length === 0) {
                tempVal = "0";
              }
              console.log(tempVal);
              this.setState(
                {
                  investedCapitalReturnRate: tempVal,
                },
                () => {
                  this.updateGraph();
                }
              );
            }}
          />
        </div>
        <hr />
        <div className="graph card">
          <Line
            height="300px"
            data={this.state.data}
            legend={this.state.legend}
            options={this.state.options}
          />
        </div>
      </div>
    );
  }
}

export default Loan;
