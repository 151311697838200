import React from "react";
import Slider from "@material-ui/core/Slider";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import { Redirect } from "react-router-dom";
class Tailer extends React.Component {
  render() {
    return (
      <div className="tailer">
        <img className="logo" src="LogoImg.png" alt="Logo" />
        <div className="tailer">ALAF a stoneg app</div>
      </div>
    );
  }
}

export default Tailer;
