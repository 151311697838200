import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Icon, InlineIcon } from "@iconify/react";
import questionCircleOutlined from "@iconify-icons/ant-design/question-circle-outlined";

class Baseline extends React.Component {
  constructor(props) {
    super();
    this.baseChange = props.onChange;
    this.state = {
      assets: props.value.assets,
      roi: props.value.roi,
      order: ["life", "base", "purchase", "risk"],
      hasHome: props.value.hasHome,
      debts: props.value.debts,
      house: props.value.house,
    };
  }

  removeDebt = (index) => {
    var debts = this.state.debts;
    debts.splice(index, 1);
    this.baseChange("debts", debts);
    this.setState({ debts: debts });
  };
  addDebt = () => {
    var debts = this.state.debts;
    debts.push({ amount: "0", rate: "0.0" });
    this.baseChange("debts", debts);
    this.setState({ debts: debts });
  };

  changeDebt = (value, id, isDebt) => {
    var debts = this.state.debts;
    debts[id] = isDebt
      ? { amount: value, rate: debts[id].rate }
      : { amount: debts[id].amount, rate: value };

    this.baseChange("debts", debts);
    this.setState({ debts: debts });
  };

  componentWillReceiveProps(props) {
    this.setState({
      hasHome: props.value.hasHome,
      assets: props.value.assets,
      roi: props.value.roi,
      debts: props.value.debts,
      house: props.value.house,
    });
  }

  changeHouses = (value, changeProp) => {
    var house = this.state.house;
    house[changeProp] = value;
    this.baseChange("house", house);
    this.setState({ house: house });
  };

  render() {
    if (this.state.debts === undefined) {
      return "d";
    }
    return (
      <div>
        <div className="header">Total Investments | Rate Of Return</div>
        <div className="percentageAddition">
          <input
            className="inputBoxesStandard"
            value={this.state.assets}
            onChange={(event) => {
              const num = event.target.value.replace(/[^0-9]/g, "");
              this.baseChange(
                "assets",
                num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              );
            }}
          ></input>
          <input
            className="inputBoxesStandard"
            value={this.state.roi}
            onChange={(event) => {
              var num = event.target.value.replace(/[^0-9,.]/g, "");
              num = num
                .replace(/^0+/, "")
                .replace(/\./, "#")
                .replace(/\./g, "")
                .replace(/#/, ".");
              if (num.toString().length < 5) {
                this.baseChange("roi", num.toString());
              }
            }}
          ></input>
        </div>
        <div className="debtsGrid">
          <div className="header"> Debts </div>
          <button
            className="makeAPlanButton goalsPlusButtons plusDebtSize"
            onClick={this.addDebt}
          >
            +
          </button>
        </div>
        <div>Amount | Interest Rate</div>
        <div>
          {this.state.debts.map((debts, i) => (
            <div className="debtsBox card" id={i}>
              <div>$</div>
              <input
                className="debtBoxDebt"
                value={debts.amount}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9]/g, "");
                  num = num.replace(/^0+/, "");
                  this.changeDebt(
                    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    i,
                    true
                  );
                }}
              ></input>
              <input
                className="debtBoxDebtPercent"
                value={debts.rate}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\./, "#")
                    .replace(/\./g, "")
                    .replace(/#/, ".");
                  if (num.toString().length < 5) {
                    this.changeDebt(num.toString(), i, false);
                  }
                }}
              ></input>
              <div>%</div>
              <button
                className="makeAPlanButton removeDebtsButton"
                onClick={() => {
                  this.removeDebt(i);
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>
        <div className="header">Do you own a home: </div>
        <Checkbox
          checked={this.state.hasHome}
          onChange={() => {
            this.baseChange("hasHome", !this.state.hasHome);
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        {this.state.hasHome ? (
          <div className="houseBoxGrid houseBoxDebt card">
            <div className="houseBox">
              <div className="flexChild">
                <div>Home Value</div>$
                <input
                  className="debtBoxDebt inputBoxWith"
                  value={this.state.house.value}
                  onChange={(event) => {
                    var num = event.target.value.replace(/[^0-9]/g, "");
                    num = num.replace(/^0+/, "");
                    this.changeHouses(
                      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      "value"
                    );
                  }}
                ></input>
              </div>

              <div className="flexChild">
                <div>Loan Interest Rate</div>
                <input
                  className="debtBoxDebt inputBoxWith"
                  value={this.state.house.interestRate}
                  onChange={(event) => {
                    var num = event.target.value.replace(/[^0-9.]/g, "");
                    num = num.replace(/^0+/, "");
                    this.changeHouses(
                      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      "interestRate"
                    );
                  }}
                ></input>
                %
              </div>
              <div className="flexChild">
                <div>Loan Amount Left</div>$
                <input
                  className="debtBoxDebt inputBoxWith"
                  value={this.state.house.loanAmount}
                  onChange={(event) => {
                    var num = event.target.value.replace(/[^0-9]/g, "");
                    num = num.replace(/^0+/, "");
                    this.changeHouses(
                      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      "loanAmount"
                    );
                  }}
                ></input>
              </div>
              <div className="flexChild">
                <div>Home Appreciation Rate</div>
                <input
                  className="debtBoxDebt inputBoxWith"
                  value={this.state.house.appreciationRate}
                  onChange={(event) => {
                    var num = event.target.value.replace(/[^0-9.]/g, "");
                    num = num.replace(/^0+/, "");
                    this.changeHouses(
                      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      "appreciationRate"
                    );
                  }}
                ></input>
                %
              </div>
              <div className="flexChild">
                <div>Mortgage</div>$
                <input
                  className="debtBoxDebt inputBoxWith"
                  value={this.state.house.monthlyCost}
                  onChange={(event) => {
                    var num = event.target.value.replace(/[^0-9]/g, "");
                    num = num.replace(/^0+/, "");
                    this.changeHouses(
                      num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                      "monthlyCost"
                    );
                  }}
                ></input>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default Baseline;
