import React from "react";
import Slider from "@material-ui/core/Slider";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
//import { Icon, InlineIcon } from "@iconify/react";
import questionCircleOutlined from "@iconify-icons/ant-design/question-circle-outlined.js";
import Tooltip from "@material-ui/core/Tooltip";
import { Icon } from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "rgba(230, 230, 230, 1)",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 20,
  },
}))(Tooltip);

const styles = () => ({
  largeIcon: {
    "& svg": {
      fontSize: 25,
    },
  },
  inputBox: {
    fontSize: "7em",
  },
});

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.handleHouseChange = this.handleHouseChange.bind(this);
    this.state = {
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "rgb(0,0,0)",
          fontSize: 20,
        },
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              label: "Dollars",
              ticks: {
                beginAtZero: true,
                userCallback: function (value, index, values) {
                  return value.toLocaleString(); // this is all we need
                },
              },
            },
          ],
        },
      },
      closing: "10,000",
      intrest: "4.0",
      house: "1471",
      houseBuy: "439,000",
      rent: "1,601",
      return: "8.0",
      tax: "0.74",
      houseval: [],
      rentMoney: [],
      hoa: "318",
      maintenance: "6,000",
      down: "90,000",
      intrestRate: "3.0",
      data: {
        labels: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "12",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        datasets: [
          {
            label: "Home Value",
            fill: false,

            backgroundColor: "rgba(0,0,0,1)",
            borderColor: "rgba(0,0,0,1)",
            data: [12],
          },
          {
            label: "Rental Investment Value ",
            fill: false,

            backgroundColor: "rgba(255,0,0,1)",
            borderColor: "rgba(255,0,0,1)",
            data: [41],
          },
          {
            label: "Liquidation Amount",
            fill: false,

            backgroundColor: "rgba(80,80,255,1)",
            borderColor: "rgba(80,80,255,1)",
            data: [41],
          },
        ],
      },
    };
    this.updateGraph();
  }

  handleChange = (event, newValue, other) => {
    console.log(other);
    const intFields = ["closing", "houseBuy", "rent", "down"];
    if (intFields.includes(event)) {
      other = parseInt(other);
    }
    if (other === undefined || isNaN(other)) {
      if (isNaN(parseInt(newValue.target.value))) {
        this.setState({ [event]: 0 }, () => {
          this.updateGraph();
        });
      } else {
        this.setState({ [event]: parseInt(newValue.target.value) }, () => {
          this.updateGraph();
        });
      }
    } else {
      if (isNaN(other)) {
        this.setState({ [event]: 0 }, () => {
          this.updateGraph();
        });
      } else {
        this.setState({ [event]: other }, () => {
          this.updateGraph();
        });
      }
    }
  };

  handleHouseChange = (value, event) => {
    if (value === 0 && event === 0) {
      console.log("hweree");
      this.setState({ intrest: this.state.intrest + "0" }, () => {
        this.updateGraph();
        this.handleHouseChange(-1, -1);
        return;
      });
    } else if (value === -1 && event === -1) {
      console.log("asdfasdfads");
      this.setState({ intrest: this.state.intrest.slice(0, -1) }, () => {
        this.updateGraph();
        return;
      });
    }
    this.setState({ [event]: value }, () => {
      this.updateGraph();
      this.setState({ intrest: this.state.intrest + "0" }, () => {
        this.updateGraph();
        this.setState({ intrest: this.state.intrest.slice(0, -1) }, () => {
          this.updateGraph();
        });
      });
    });
    if (event === "houseBuy") {
      var down = this.getInt(this.getInt(value) * 0.2)
        .toLocaleString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.setState({ down: down }, () => {
        this.updateGraph();
      });
    }
  };

  getInt = (value) => {
    try {
      return parseInt(value.replaceAll(",", ""));
    } catch (error) {
      return value;
    }
  };
  getFloat = (value) => {
    try {
      return parseFloat(value.replaceAll(",", ""));
    } catch (error) {
      return value;
    }
  };

  updateGraph = () => {
    var newList = [];
    var rentmoneylist = [];
    var sellammount = [];
    var prevMoney =
      this.getInt(this.state.down) + this.getInt(this.state.closing); // + this.state.closing;
    console.log(this.state.houseBuy);
    var loanIntrestRate = this.getFloat(this.state.intrestRate) / 1200;
    var monthlyPayments =
      ((this.getInt(this.state.houseBuy) - this.getInt(this.state.down)) *
        (loanIntrestRate * Math.pow(1 + loanIntrestRate, 360))) /
      (Math.pow(1 + loanIntrestRate, 360) - 1);
    this.setState({ house: Math.floor(monthlyPayments) });
    console.log(monthlyPayments);
    var loanLeft =
      this.getInt(this.state.houseBuy) - this.getInt(this.state.down);

    for (var i = 0; i < 30; i++) {
      var powernum = this.getFloat(this.state.intrest) / 100.0 + 1.0;
      var loanLeft =
        loanLeft * (1 + this.getFloat(this.state.intrestRate) / 100) -
        monthlyPayments * 12;

      newList.push(this.getInt(this.state.houseBuy) * Math.pow(powernum, i));
      prevMoney = prevMoney * (this.getFloat(this.state.return) / 100.0 + 1.0);

      prevMoney =
        prevMoney +
        (this.getInt(this.state.house) +
          this.getInt(this.state.hoa) -
          this.getInt(this.state.rent) * Math.pow(powernum, i)) *
          12 +
        (this.getFloat(this.state.tax) / 100) *
          this.getInt(this.state.houseBuy) +
        this.getInt(this.state.maintenance);
      rentmoneylist.push(prevMoney);

      sellammount.push(
        this.getInt(this.state.houseBuy) * Math.pow(powernum, i) - loanLeft
      );
    }

    var holder = this.state.data;
    holder.datasets[0].data = newList;
    holder.datasets[1].data = rentmoneylist;
    holder.datasets[2].data = sellammount;
    console.log(holder);

    this.setState({ data: holder }, () => {
      this.forceUpdate();
    });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="sliders_box">
        <div className="blockTitle">
          <div className="header">Rent Vs. Buy</div>
        </div>

        <Icon icon={questionCircleOutlined} data-tip data-for="registerTip90" />
        <ReactTooltip id="registerTip90" place="right" effect="solid">
          Rent Vs. Buy compares buying vs renting a house or apartment assuming
          that if you were renting you would be investing the difference between
          your rent payment and your would be mortgage payment and all other
          house expenses. It assumes that you would be renting/owning in a
          similar location and that the standard of living of the place you
          would be consistent weather renting or owning.
        </ReactTooltip>
        <p></p>
        <div className="rentbuycard card">
          <div>
            Loan Amount:{" "}
            {Math.floor(
              this.getInt(this.state.houseBuy) - this.getInt(this.state.down)
            ).toLocaleString()}
          </div>
          <div>
            Mortgage Payment: {Math.floor(this.state.house).toLocaleString()}
          </div>
          <div>
            Monthly cost:{" "}
            {Math.floor(
              this.getInt(this.state.house) +
                this.getInt(this.state.hoa) +
                this.getInt(this.state.maintenance) / 12
            ).toLocaleString()}
          </div>
        </div>
        <hr />

        <div className="houseBoxDebt card">
          <div className="houseBox">
            <div className="flexChild">
              <div className="iconDesc">
                Home Value
                <LightTooltip title="Total price of the home">
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.houseBuy}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  this.handleHouseChange(num, "houseBuy");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flexChild">
              <div className="iconDesc">
                Money Down
                <LightTooltip title="Money put down on the house, typically 20% of the total house value">
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.down}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  this.handleHouseChange(num, "down");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flexChild">
              <div className="iconDesc">
                Closing Cost
                <LightTooltip
                  title="Cost of closing, will be considered part of the upfront cost
                  but does not count towards house equity"
                >
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.closing}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  this.handleHouseChange(num, "closing");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flexChild">
              <div className="iconDesc">
                Loan Interest Rate
                <LightTooltip title="Loan Intrest Rate">
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.intrestRate}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  console.log(num);
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\./, "#")
                    .replace(/\./g, "")
                    .replace(/#/, ".");
                  this.handleHouseChange(num, "intrestRate");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flexChild">
              <div className="iconDesc">
                Home Appreciation Rate
                <LightTooltip
                  title="Expected annual percentage appreciation of house price (Typically
                    between 3-5%)"
                >
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.intrest}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\./, "#")
                    .replace(/\./g, "")
                    .replace(/#/, ".");
                  this.handleHouseChange(num, "intrest");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flexChild">
              <div className="iconDesc">
                Monthly HOA
                <LightTooltip title="Monthly HOA Payment">
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.hoa}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  this.handleHouseChange(num, "hoa");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flexChild">
              <div className="iconDesc">
                Tax Rate
                <LightTooltip title="Property Tax rate">
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.tax}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\./, "#")
                    .replace(/\./g, "")
                    .replace(/#/, ".");
                  this.handleHouseChange(num, "tax");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="flexChild">
              <div className="iconDesc">
                Annual Maintenance
                <LightTooltip title="Expected annual maintenance cost (1-4% of house price)">
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.maintenance}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  this.handleHouseChange(num, "maintenance");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>

        <hr />
        <div className="houseBoxDebt card">
          <div className="houseBox">
            <div className="flexChild">
              <div className="iconDesc">
                Rent
                <LightTooltip title="Expected monthly rent cost">
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.rent}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                  this.handleHouseChange(num, "rent");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="flexChild">
              <div className="iconDesc">
                Investment Return
                <LightTooltip
                  title="Expected annual investment return percentage (5-10% depending on
          investment)"
                >
                  <IconButton className={classes.largeIcon}>
                    <HelpIcon />
                  </IconButton>
                </LightTooltip>
              </div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                className={classes.inputBox}
                value={this.state.return}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num
                    .replace(/^0+/, "")
                    .replace(/\./, "#")
                    .replace(/\./g, "")
                    .replace(/#/, ".");
                  this.handleHouseChange(num, "return");
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
        </div>

        <div className="graph card">
          <Line
            height="300px"
            data={() => {
              return this.state.data;
            }}
            legend={this.state.legend}
            options={this.state.options}
            ref={(reference) => (this.chartReference = reference)}
            onChange={(value) => {
              console.log(value);
            }}
          />
        </div>
      </div>
    );
  }
}
export default withStyles(styles)(Home);

/*
<div data-tip data-for="registerTip" className="slider_title">
          Closing Cost
        </div>
        <ReactTooltip id="registerTip" place="right" effect="solid">
          Cost of closing, will be considered part of the upfront cost but does
          not count towards house equity
        </ReactTooltip>
        <div>{this.state.closing.toLocaleString()}</div>
        <Slider
          className
          value={this.state.closing}
          onChange={this.handleChange.bind(this, "closing")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.0}
          max={100000.0}
        />
        <div data-tip data-for="registerTip2" className="slider_title">
          House Price
        </div>
        <ReactTooltip id="registerTip2" place="right" effect="solid">
          Total price of the home
        </ReactTooltip>
        <div>{this.state.houseBuy.toLocaleString()}</div>
        <input
          type="text"
          value={this.state.houseBuy}
          onChange={this.handleChange.bind(this, "houseBuy")}
        />
        <Slider
          className
          value={this.state.houseBuy}
          onChange={this.handleChange.bind(this, "houseBuy")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.0}
          max={1000000.0}
        />
        <div data-tip data-for="registerTip3" className="slider_title">
          Money Down
        </div>
        <ReactTooltip id="registerTip3" place="right" effect="solid">
          Money put down on the house, typically 20% of the total house value
        </ReactTooltip>
        <div>{this.state.down.toLocaleString()}</div>
        <Slider
          className
          value={this.state.down}
          onChange={this.handleChange.bind(this, "down")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.0}
          max={1000000.0}
        />
        <div data-tip data-for="registerTip4" className="slider_title">
          House Appreciation Value
        </div>
        <ReactTooltip id="registerTip4" place="right" effect="solid">
          Expected annual percentage appreciation of house price (Typically
          between 3-5%)
        </ReactTooltip>
        <div>{this.state.intrest.toLocaleString()}</div>
        <Slider
          className
          value={this.state.intrest}
          onChange={this.handleChange.bind(this, "intrest")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.0}
          max={10.0}
        />
        <div data-tip data-for="registerTip5" className="slider_title">
          Tax
        </div>
        <ReactTooltip id="registerTip5" place="right" effect="solid">
          Property Tax rate
        </ReactTooltip>
        <div>{this.state.tax.toLocaleString()}</div>
        <Slider
          className
          value={this.state.tax}
          onChange={this.handleChange.bind(this, "tax")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.0}
          max={10.0}
        />
        <div data-tip data-for="registerTip5" className="slider_title">
          Loan Intrest Rate
        </div>
        <ReactTooltip id="registerTip5" place="right" effect="solid">
          Intrest rate of home loan
        </ReactTooltip>
        <div>{this.state.intrestRate.toLocaleString()}</div>
        <Slider
          className
          value={this.state.intrestRate}
          onChange={this.handleChange.bind(this, "intrestRate")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.01}
          max={20.0}
        />
        <div data-tip data-for="registerTip6" className="slider_title">
          Hoa Payment
        </div>
        <ReactTooltip id="registerTip6" place="right" effect="solid">
          Monthly HOA Payment
        </ReactTooltip>
        <div>{this.state.hoa.toLocaleString()}</div>
        <Slider
          className
          value={this.state.hoa}
          onChange={this.handleChange.bind(this, "hoa")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.0}
          max={1000.0}
        />
        <div data-tip data-for="registerTip7" className="slider_title">
          Annual Maintenance
        </div>
        <ReactTooltip id="registerTip7" place="right" effect="solid">
          Expected annual maintenance cost (1-4% of house price)
        </ReactTooltip>
        <div>{this.state.maintenance.toLocaleString()}</div>
        <Slider
          className
          value={this.state.maintenance}
          onChange={this.handleChange.bind(this, "maintenance")}
          aria-labelledby="continuous-slider"
          step={0.01}
          min={0.0}
          max={20000.0}
        />
*/
