import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  BrowserRouter,
  Redirect,
} from "react-router-dom";
import "./App.css";
import Home from "./Home.js";
import Header from "./Header.js";
import Info from "./Info.js";
import Tailer from "./Tailer.js";
import Salary from "./SalaryCompoud.js";
import Loan from "./PayoffLoan.js";
import Goals from "./Goals.js";
import { Parallax, ParallaxProvider } from "react-scroll-parallax";

function App() {
  return (
    <div className="master">
      <Router>
        <Switch>
          <Route exact path="/">
            <Redirect to="/info"></Redirect>
          </Route>
          <Route exact path="/home">
            <div>
              <Header pageWrapId={"page-wrap"} highlight={"home"} />
              <Home id="page-wrap" />
              <Tailer id="page-wrap" />
            </div>
          </Route>
          <Route exact path="/info">
            <Header pageWrapId={"page-wrap"} highlight={"info"} />
            <Info id="page-wrap" />
          </Route>
          <Route exact path="/compound">
            <div>
              <Header pageWrapId={"page-wrap"} highlight={"compound"} />
              <Salary id="page-wrap" />
              <Tailer id="page-wrap" />
            </div>
          </Route>
          <Route exact path="/loan">
            <div>
              <Header pageWrapId={"page-wrap"} highlight={"loan"} />
              <Loan id="page-wrap" />
              <Tailer id="page-wrap" />
            </div>
          </Route>
          <Route exact path="/goals">
            <div>
              <Header pageWrapId={"page-wrap"} highlight={"goals"} />
              <Goals id="page-wrap" />
              <Tailer id="page-wrap" />
            </div>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
