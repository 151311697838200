import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Icon, InlineIcon } from "@iconify/react";
import questionCircleOutlined from "@iconify-icons/ant-design/question-circle-outlined";

class Lifestyle extends React.Component {
  constructor(props) {
    console.log(props);
    super();
    this.goalsChange = props.onChange;
    this.state = {
      toHaveYear: "10",
      toSaveTarget: "100,000",
      toHaveTarget: "100,000",
      toHaveClicked: false,
      life: true,
      base: false,
      purchase: false,
      risk: false,
      order: ["life", "base", "purchase", "risk"],
      checked: props.value.checked,
      monthlySpend: props.value.monthlySpend,
      monthlySavings: props.value.monthlySavings,
      monthlyIncome: props.value.monthlyIncome,
      investmentInterestRate: props.value.investmentInterestRate,
      incomeIncreases: props.value.incomeIncreases,
      mortgage: props.value.mortgage,
    };
  }
  /*
  const [monthlySpend, setMonthlySpend] = useState("1,000");
  const [monthlyIncome, setMonthlyIncome] = useState("2,500");
  const [monthlySavings, setMonthlySavings] = useState("1,500");
  const [checked, setChecked] = useState(true);
  */
  componentWillReceiveProps(props) {
    console.log(props);
    this.setState({
      checked: props.value.checked,
      monthlySpend: props.value.monthlySpend,
      monthlySavings: props.value.monthlySavings,
      monthlyIncome: props.value.monthlyIncome,
      investmentInterestRate: props.value.investmentInterestRate,
      incomeIncreases: props.value.incomeIncreases,
      mortgage: props.value.mortgage,
    });
  }

  removePay = (index) => {
    var incomeIncreases = this.state.incomeIncreases;
    incomeIncreases.splice(index, 1);
    this.goalsChange("incomeIncreases", incomeIncreases);
    this.setState({ incomeIncreases: incomeIncreases });
  };
  addPay = () => {
    var incomeIncreases = this.state.incomeIncreases;
    incomeIncreases.push({ amount: "0", yearsAway: "0" });
    this.goalsChange("incomeIncreases", incomeIncreases);
    this.setState({ incomeIncreases: incomeIncreases });
  };

  changePay = (value, id, isAmount) => {
    var incomeIncreases = this.state.incomeIncreases;
    incomeIncreases[id] = isAmount
      ? { amount: value, yearsAway: incomeIncreases[id].yearsAway }
      : { amount: incomeIncreases[id].amount, yearsAway: value };

    this.goalsChange("incomeIncreases", incomeIncreases);
    this.setState({ incomeIncreases: incomeIncreases });
  };

  render() {
    return (
      <div>
        <div className="header">Monthly Spend: </div>
        <input
          className="inputBoxesStandard"
          value={this.state.monthlySpend}
          onChange={(event) => {
            const num = event.target.value.replace(/[^0-9]/g, "");
            this.goalsChange(
              "monthlySpend",
              num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
          }}
        ></input>
        <div className="header">Rent/Mortgage Monthly Payment: </div>
        <input
          className="inputBoxesStandard"
          value={this.state.mortgage}
          onChange={(event) => {
            const num = event.target.value.replace(/[^0-9]/g, "");
            this.goalsChange(
              "mortgage",
              num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
          }}
        ></input>
        <div className="header">Monthly Income (after taxes): </div>
        <input
          className="inputBoxesStandard"
          value={this.state.monthlyIncome}
          onChange={(event) => {
            const num = event.target.value.replace(/[^0-9]/g, "");
            this.goalsChange(
              "monthlyIncome",
              num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
          }}
        ></input>
        <div className="header">Include adjustments for inflation: </div>
        <Checkbox
          checked={this.state.checked}
          onChange={() => {
            this.goalsChange("checked", !this.state.checked);
          }}
          color="primary"
          inputProps={{ "aria-label": "primary checkbox" }}
        />
        <div className="debtsGrid">
          <div className="header"> Income Increases </div>
          <button
            className="makeAPlanButton goalsPlusButtons plusDebtSize"
            onClick={this.addPay}
          >
            +
          </button>
        </div>
        <div>Additional Monthly Amount | Years Away</div>
        <div>
          {this.state.incomeIncreases.map((incomeIncreases, i) => (
            <div className="debtsBox card" id={i}>
              <div>$</div>
              <input
                className="debtBoxDebt"
                value={incomeIncreases.amount}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9]/g, "");
                  num = num.replace(/^0+/, "");
                  this.changePay(
                    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    i,
                    true
                  );
                }}
              ></input>
              <input
                className="debtBoxDebtPercent"
                value={incomeIncreases.yearsAway}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.]/g, "");
                  num = num.replace(/^0+/, "");
                  if (num.toString().length < 5) {
                    this.changePay(num.toString(), i, false);
                  }
                }}
              ></input>

              <button
                className="makeAPlanButton removeDebtsButton"
                onClick={() => {
                  this.removePay(i);
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Lifestyle;
