import React, { useState } from "react";
import { Line } from "react-chartjs-2";
var resultz = 0;
class Results extends React.Component {
  constructor(props) {
    super();
    this.state = {
      lifestyle: props.goals,
      baseline: props.baseline,
      purchase: props.purchase,
      toHaveYear: props.toHaveYear,
      toHaveTarget: this.getInt(props.toHaveTarget),
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              label: "Dolars",
              ticks: {
                beginAtZero: true,
                userCallback: function (value, index, values) {
                  return value.toLocaleString(); // this is all we need
                },
              },
            },
          ],
        },
      },
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "rgb(0,0,0)",
          fontSize: 20,
        },
      },
      data: {
        labels: [],
        datasets: [
          {
            label: "Net Worth",
            fill: false,
            backgroundColor: "rgba(100,0,0,1)",
            borderColor: "rgba(100,0,0,1)",
            data: [],
          },
          {
            label: "House Equity",
            fill: false,
            backgroundColor: "rgba(0,200,0,1)",
            borderColor: "rgba(0,200,0,1)",
            data: [],
          },
          {
            label: "Investment Equity",
            fill: false,
            backgroundColor: "rgba(0,0,0,1)",
            borderColor: "rgba(0,0,0,1)",
            data: [],
          },
        ],
      },
    };
    this.updateGraph();
  }

  componentWillReceiveProps(props) {
    this.forceUpdate();
    this.state.toHaveYear = this.getInt(props.toHaveYear);
    this.state.toHaveTarget = this.getInt(props.toHaveTarget);
    this.updateGraph();
  }

  updateGraph = () => {
    var dataHolder = this.state.data;
    dataHolder.labels = range(0, this.state.toHaveYear);
    const dataArrays = this.makeData();
    dataHolder.datasets[0].data = dataArrays[0];
    dataHolder.datasets[1].data = dataArrays[1];
    dataHolder.datasets[2].data = dataArrays[2];
    var results = "";
    if (
      dataArrays[0][dataArrays[0].length - 1] <
      0.9 * this.getInt(this.state.toHaveTarget)
    ) {
      results = "You may need to make significant changes to meet your goals";
    } else if (
      dataArrays[0][dataArrays[0].length - 1] >
      1.1 * this.getInt(this.state.toHaveTarget)
    ) {
      results = "You have room to raise your goals!";
    } else {
      results = "You are right on track to meet your goals!";
    }
    this.state.results = results;
    this.setState({ data: dataHolder });
  };

  makeData = () => {
    var value = [parseInt(this.state.baseline.assets.replace(",", ""))];
    var debts = [];
    var homesVal = [];
    this.state.baseline.debts.forEach((debt) => {
      debts.push({
        amount: parseInt(debt.amount.replace(",", "")),
        rate: parseInt(debt.rate.replace(",", "")),
      });
    });

    var houseValues = [this.buildHouses(), false];
    if (this.state.baseline.hasHome) {
      const baseLineHome = new home(this.state.baseline.house);
      houseValues[1] = true;
      houseValues[0].push(baseLineHome);
      homesVal.push(
        this.getInt(baseLineHome.value) - this.getInt(baseLineHome.loanAmount)
      );
    } else {
      homesVal.push(0);
    }

    for (const x of Array(parseInt(this.state.toHaveYear)).keys()) {
      var yearlySavings = houseValues[1]
        ? this.getYearlySavings(
            x,
            houseValues[0][0].monthlyCost,
            houseValues[0][0].loan
          )
        : this.getYearlySavings(x);
      var bigRockCost = this.getBigRocks(x);
      var savingsAfterRock = yearlySavings - bigRockCost;
      if (savingsAfterRock < 0) {
        savingsAfterRock = 0;
        bigRockCost -= yearlySavings;
      }
      let payDebt = this.payDebt(savingsAfterRock, debts);

      houseValues = this.houseValue(houseValues[0], x);

      yearlySavings = payDebt[1];
      debts = payDebt[0];
      value.push(
        value[value.length - 1] *
          (1 + this.getFloat(this.state.baseline.roi) / 100) +
          yearlySavings -
          bigRockCost
      );
      var homeValTot = 0;
      houseValues[0].forEach((house) => {
        if (this.getInt(house.yearsAway) <= x) {
          homeValTot += this.getInt(house.inHome);
        }
      });
      homesVal.push(homeValTot);
    }
    const netWorth = value.map((a, i) => a + homesVal[i]);

    return [netWorth, homesVal, value];
  };

  houseValue = (houses, year) => {
    var houseEquity = houses;
    var livingInHouse = false;

    houses.forEach((house, i) => {
      if (parseInt(house.yearsAway) === 0) {
        houses[i].loan = this.getInt(house.value) - this.getInt(house.down);
        livingInHouse = true;
        houses[i].inHome = this.getInt(house.down);
        houses[i].value = house.value;
      } else if (house.yearsAway < year) {
        livingInHouse = true;
        if (
          0 >
          house.loan * (1 + this.getInt(house.interestRate) / 100) -
            this.getInt(house.monthlyCost) * 12
        ) {
          houses[i].loanAmount = 0;
          houses[i].value =
            this.getInt(house.value) * (1 + house.appreciationRate / 100);
          houses[i].inHome = houses[i].value;
        } else {
          houses[i].loanAmount =
            this.getInt(house.loanAmount) * (1 + house.interestRate / 100) -
            this.getInt(house.monthlyCost) * 12;

          houses[i].value =
            this.getInt(house.value) *
            (1 + this.getFloat(house.appreciationRate) / 100);
          houses[i].inHome = houses[i].value - houses[i].loanAmount;
        }
      }
    });
    return [houseEquity, livingInHouse];
  };

  getInt = (value) => {
    try {
      return parseInt(value.replaceAll(",", ""));
    } catch (error) {
      return value;
    }
  };
  getFloat = (value) => {
    try {
      return parseFloat(value.replaceAll(",", ""));
    } catch (error) {
      return value;
    }
  };

  buildHouses = () => {
    var housearray = [];
    this.state.purchase.houses.forEach((house) => {
      var tempHouse = new home(house);
      tempHouse.loanAmount =
        this.getInt(tempHouse.value) - this.getInt(tempHouse.inHome);
      housearray.push(tempHouse);
    });
    return housearray;
  };

  payDebt = (extra, debts) => {
    debts.forEach(function (debt, i, debts) {
      let amount = parseInt(debt.amount);
      let rate = parseInt(debt.rate);
      if (extra >= amount) {
        extra = extra - amount;
        debts[i].amount = 0;
      } else {
        debts[i].amount = (amount - extra) * (1 + rate / 100);
        extra = 0;
      }
    });
    return [debts, extra];
  };

  getBigRocks = (x) => {
    var rockCost = 0;
    for (const rock of this.state.purchase.purchases) {
      if (this.getInt(rock.years) === x) {
        rockCost += parseInt(rock.amount.replace(",", ""));
      }
    }
    for (const rock of this.state.purchase.houses) {
      if (this.getInt(rock.yearsAway) === x) {
        rockCost += parseInt(rock.down.replace(",", ""));
      }
    }
    return rockCost;
  };
  getYearlySavings = (x, col = this.state.lifestyle.mortgage, loan = 1) => {
    var inflation = this.state.lifestyle.checked ? 1.02 : 1.0;
    if (loan === 0) {
      col = "0";
    }
    var savings =
      parseInt(this.state.lifestyle.monthlyIncome.replace(",", "")) *
        Math.pow(inflation, x) -
      parseInt(this.state.lifestyle.monthlySpend.replace(",", "")) *
        Math.pow(inflation, x) -
      parseInt(col.replace(",", "")) * Math.pow(inflation, x);

    for (const increase of this.state.lifestyle.incomeIncreases) {
      if (parseInt(increase.yearsAway.replace(",", "")) <= x) {
        savings += parseInt(increase.amount.replace(",", ""));
      }
    }
    return savings * 12;
  };

  render() {
    return (
      <div>
        <div className="header">{this.state.results}</div>
        <hr></hr>
        <div className="graph card">
          <Line
            height="300px"
            data={this.state.data}
            legend={this.state.legend}
            options={this.state.options}
          />
        </div>
      </div>
    );
  }
}

export default Results;

function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

class home {
  constructor(home) {
    this.monthlyCost = home.monthlyCost;
    this.appreciationRate = home.appreciationRate;
    this.loanAmount = home.loanAmount;
    this.interestRate = home.interestRate;
    this.value = home.value;
    this.yearsAway = home.yearsAway;
    this.inHome = home.down;
  }
}
