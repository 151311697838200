import React from "react";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Icon, InlineIcon } from "@iconify/react";
import questionCircleOutlined from "@iconify-icons/ant-design/question-circle-outlined";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

class Salary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inflationRate: 2.0,
      checked: false,
      timeline: [
        { years: 2, savings: 30000, inflationRate: 0 },
        { years: 5, savings: 50000, inflationRate: 0 },
      ],
      legend: {
        display: true,
        position: "bottom",
        labels: {
          fontColor: "rgb(0,0,0)",
          fontSize: 20,
        },
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              label: "Dolars",
              ticks: {
                beginAtZero: true,
                userCallback: function (value, index, values) {
                  return value.toLocaleString(); // this is all we need
                },
              },
            },
          ],
        },
      },
      initialCapital: "100,000",
      intrestRate: "8.0",
      timelineLength: 0,
      additionArray: [],
      years: "0",
      savingsAmmount: "0",
      data: {
        labels: [],
        datasets: [
          {
            label: "Investment Value",
            fill: false,
            backgroundColor: "rgba(0,0,0,1)",
            borderColor: "rgba(0,0,0,1)",
            data: [12],
          },
        ],
      },
    };
    this.updateGraph();
  }

  handleChange = (event, newValue, other) => {
    this.setState({ [event]: other });
    this.updateGraph();
  };
  updateGraph = () => {
    var capital = [];
    console.log(this.state.initialCapital);
    capital.push(parseFloat(this.state.initialCapital.replaceAll(",", "")));
    var intrest = parseFloat(this.state.intrestRate) / 100.0 + 1.0;

    var timeLineData = this.updateLength();
    for (var i = 0; i < timeLineData.len; i++) {
      capital.push(
        (
          capital[capital.length - 1] * intrest +
          timeLineData.additions[i]
        ).toFixed(2)
      );
    }

    var holder = this.state.data;
    holder.datasets[0].data = capital;
    this.setState({ data: holder });
  };

  updateLength = () => {
    var timelineLen = 0;
    var additionArray = [];

    this.state.timeline.forEach((entry) => {
      var inflation = parseFloat(entry.inflationRate) / 100.0 + 1.0;
      timelineLen += entry.years;
      for (var i = 0; i < entry.years; i++) {
        var intrestChange = Math.pow(inflation, i);
        additionArray.push(entry.savings * intrestChange);
      }
    });
    this.setState({ additionArray: additionArray });
    this.setState({ timelineLength: timelineLen });
    var labelHolder = [];
    for (var i = 0; i <= timelineLen; i++) {
      labelHolder.push(String(i));
    }
    var holder = this.state.data;
    holder.labels = labelHolder;
    return { len: timelineLen, additions: additionArray };
  };
  handleCheckboxChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    var thisyear = new Date().getFullYear();
    return (
      <div className="sliders_box">
        <div className="blockTitle">
          <div className="header">
            Investment Calculator with Dynamic Savings Amount
          </div>
        </div>
        <Icon icon={questionCircleOutlined} data-tip data-for="registerTip92" />
        <ReactTooltip id="registerTip92" place="right" effect="solid">
          Investment Calculator with Dynamic Savings Amount allows you to
          calculate how much you will have invested given different years of
          investing different amounts. For instance in the example below this
          person is going to invest 30k a year for 2 years and then 50k a year
          for 5 years. Their expected annual return on investment is set to 8%
          and the amount they have invested right now is 100k.
        </ReactTooltip>
        <p></p>

        <div data-tip data-for="registerTip" className="slider_title">
          Initial Capital
        </div>
        <ReactTooltip id="registerTip" place="right" effect="solid">
          Starting capital invested at intrest rate below
        </ReactTooltip>
        <div className="investmentCalculatorFlex">
          <div>
            <div>
              <TextField
                id="standard-basic"
                //className="debtBoxDebt inputBoxWith"
                size="medium"
                value={this.state.initialCapital}
                onChange={(event) => {
                  var tempVal = event.target.value;
                  tempVal = tempVal
                    .replace(/[^\d.-]/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    .replace(/^0+/, "");
                  if (tempVal.length === 0) {
                    tempVal = "0";
                  }
                  console.log(tempVal);
                  this.setState(
                    {
                      initialCapital: tempVal,
                    },
                    () => {
                      this.updateGraph();
                    }
                  );
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </div>
            <div data-tip data-for="registerTip2" className="slider_title">
              Interest Rate
            </div>
            <ReactTooltip id="registerTip2" place="right" effect="solid">
              Expected Rate of Return of the Savings Amount
            </ReactTooltip>

            <div>
              <TextField
                value={this.state.intrestRate}
                onChange={(event) => {
                  var tempVal = event.target.value;
                  tempVal = tempVal
                    .replace(/[^\d.-]/g, "")
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    .replace(/^0+/, "");
                  if (tempVal.length === 0) {
                    tempVal = 0;
                  }
                  this.setState(
                    {
                      intrestRate: tempVal,
                    },
                    () => {
                      this.updateGraph();
                    }
                  );
                }}
                InputProps={{
                  style: { fontSize: 20 },
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="timebox card">
              <div className="timesegment">
                Years of Savings:
                <TextField
                  value={this.state.years}
                  maxLength="4"
                  onChange={(event) => {
                    var tempVal = event.target.value;
                    tempVal = tempVal
                      .replace(/[^\d.-]/g, "")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      .replace(/^0+/, "");
                    if (tempVal.length === 0) {
                      tempVal = 0;
                    }
                    this.setState({
                      years: tempVal,
                    });
                  }}
                  InputProps={{
                    style: { fontSize: 20, width: "200px" },
                  }}
                />
              </div>
              <div className="timesegment">
                Savings Amount:
                <TextField
                  value={this.state.savingsAmmount}
                  maxLength="16"
                  onChange={(event) => {
                    var tempVal = event.target.value;
                    tempVal = tempVal
                      .replace(/[^\d.-]/g, "")
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      .replace(/^0+/, "");
                    if (tempVal.length === 0) {
                      tempVal = 0;
                    }
                    this.setState({
                      savingsAmmount: tempVal,
                    });
                  }}
                  InputProps={{
                    style: { fontSize: 20, width: "200px" },
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <div>Include Annual Increases</div>
                <input
                  type="checkbox"
                  checked={this.state.checked}
                  onChange={this.handleCheckboxChange}
                />
              </div>
              {this.state.checked ? (
                <div className="timesegment">
                  Annual Contribution Percentage Increase:
                  <TextField
                    value={this.state.inflationRate}
                    maxLength="5"
                    onChange={(event) => {
                      var tempVal = event.target.value;
                      tempVal = tempVal
                        .replace(/[^\d.-]/g, "")
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        .replace(/^0+/, "");
                      if (tempVal.length === 0) {
                        tempVal = 0;
                      }
                      this.setState({
                        inflationRate: tempVal,
                      });
                    }}
                    InputProps={{
                      style: { fontSize: 20, width: "200px" },
                      startAdornment: (
                        <InputAdornment position="start">%</InputAdornment>
                      ),
                    }}
                  />
                </div>
              ) : null}

              <button
                className="timebutton plus"
                onClick={() => {
                  var timehold = this.state.timeline;
                  console.log(this.state.savingsAmmount.replaceAll(",", ""));
                  timehold.push({
                    years: parseInt(this.state.years),
                    savings: parseInt(
                      this.state.savingsAmmount.replaceAll(",", "")
                    ),
                    inflationRate: this.state.checked
                      ? this.state.inflationRate
                      : 0,
                  });
                  this.setState({
                    timeline: timehold,
                    years: "0",
                    savingsAmmount: "0",
                  });
                  this.updateGraph();
                }}
              >
                +
              </button>
            </div>
          </div>
          <div>
            <div className="timeline">
              <Timeline>
                {this.state.timeline.map((componentName, i) => {
                  thisyear += componentName.years;
                  return (
                    <TimelineItem>
                      <TimelineOppositeContent>
                        {thisyear - componentName.years}
                      </TimelineOppositeContent>

                      <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <div className="timebox card">
                          <div className="timesegment">
                            Years of Savings: {componentName.years}
                          </div>
                          <div className="timesegment">
                            Savings Amount:{" "}
                            {String(componentName.savings).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </div>
                          <div className="timesegment">
                            Annual Percent Increase:{" "}
                            {String(componentName.inflationRate).replace(
                              /\B(?=(\d{3})+(?!\d))/g,
                              ","
                            )}
                          </div>
                          <button
                            className="timebutton"
                            onClick={() => {
                              var timehold = this.state.timeline;
                              console.log(timehold.splice(i, 1));
                              this.setState({ timeline: timehold });
                              this.updateGraph();
                            }}
                          >
                            X
                          </button>
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
                <TimelineItem>
                  <TimelineOppositeContent>{thisyear}</TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent> </TimelineContent>
                </TimelineItem>
              </Timeline>
            </div>
          </div>
        </div>
        <hr />

        <div className="graph card">
          <Line
            height="300px"
            data={this.state.data}
            legend={this.state.legend}
            options={this.state.options}
          />
        </div>
      </div>
    );
  }
}

export default Salary;
