import React, { useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import { Redirect } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import Aos from "aos";
import "aos/dist/aos.css";
import Tailer from "./Tailer.js";
import { Parallax } from "react-parallax";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const Info = () => {
  var imgRef = React.createRef();
  const [redirect, setRedirect] = useState("");
  const [imgHeight, setImageHeight] = useState("");

  const [rentBuyOver, setRentBuyOver] = useState(false);
  const [compoundOver, setCompoundOver] = useState(false);
  const [payoffOver, setPayoffOver] = useState(false);
  const [goalsOver, setGoalsOver] = useState(false);
  const onLoad = (imgData) => {
    console.log(imgData.target.offsetHeight);
    console.log(parseInt(imgData.target.offsetHeight * 0.21 + 56).toString());
    setImageHeight((imgData.target.offsetHeight * 0.21 + 56).toString());
  };
  useEffect(() => {
    Aos.init({ duration: 1000 });
    Aos.refresh();
  }, []);

  if (redirect !== "") {
    return <Redirect to={`/${redirect}`} />;
  } else {
    return (
      <div>
        <img src="IMG_1965.JPG" className="hiddenimg"></img>
        <div className="infoBody">
          <div className="info">
            <p>
              ALAF (A Look At Finances) is a set of visualizations related to
              finances. They cover various scenarios that can reveal some
              counter intuitive results. The focus is on giving an easy way to
              map out finances and visualize what to expect.
            </p>
          </div>

          <h1>The Calculators:</h1>
          <div className="calculatorsflex">
            <div
              className="info"
              onMouseOver={() => {
                setRentBuyOver(true);
              }}
              onMouseOut={() => {
                setRentBuyOver(false);
              }}
            >
              <div
                className="card insideCard"
                onClick={() => setRedirect("home")}
              >
                <div>
                  <h1>Rent Vs. Buy</h1>
                  <p>
                    Rent Vs. Buy is a simple calculator that compares two
                    scenarios and shows you the financial outcome. Assuming you
                    have enough money for all the costs of purchasing and owning
                    a home Rent Vs. Buy compares if you would be better off
                    renting or buying for your given scenario. Note that Rent
                    Vs. Buy compares two properties that have the same or
                    similar standards of living, if you adjust your standard of
                    living either way you can make either option viable.{" "}
                  </p>
                  <p>
                    Rental rates are also subject to annual increases that match
                    the annual property value increase. This stems from the fact
                    that on average for a given area they should match assuming
                    that the housing market is efficient. This leads to an
                    occasional dip in total rental investment portfolio.
                  </p>
                </div>
                <div className="imgGrid">
                  <img src="rentPlace2.png" className="cardImg2"></img>
                  <img
                    src="house.png"
                    className="cardImg2"
                    style={{ float: "right" }}
                  ></img>
                </div>
                <div className={rentBuyOver ? "hover" : "unhover"}>
                  <ChevronRightIcon fontSize="large"></ChevronRightIcon>
                  <div>Calculate </div>
                </div>
              </div>
            </div>

            <div
              className="info"
              onMouseOver={() => {
                setCompoundOver(true);
              }}
              onMouseOut={() => {
                setCompoundOver(false);
              }}
            >
              <div
                className="card insideCard"
                onClick={() => setRedirect("compound")}
              >
                <div>
                  <h1>Compound Interest</h1>
                  <p>
                    Compound interest calculator is a compound interest
                    calculator with changes in saving amounts taken into
                    account. You can map out what your investment account would
                    look like with differing amounts of annual additions and
                    growth.
                  </p>
                </div>
                <img src="money1.png" className="cardImg"></img>{" "}
                <img src="money2.png" className="cardImg"></img>
                <img src="money3.png" className="cardImg"></img>
                <div className={compoundOver ? "hover" : "unhover"}>
                  <ChevronRightIcon fontSize="large"></ChevronRightIcon>
                  <div>Calculate </div>
                </div>
              </div>
            </div>

            <div
              className="info"
              onMouseOver={() => {
                setPayoffOver(true);
              }}
              onMouseOut={() => {
                setPayoffOver(false);
              }}
            >
              <div
                className="card insideCard"
                onClick={() => setRedirect("loan")}
              >
                <div>
                  <h1>Payoff Loan or Invest</h1>
                  <p>
                    Payoff Loan or Invest is a calculator that considers the
                    most and the least you could pay on a fixed rate loan. From
                    this it assumes that the difference between the most you can
                    pay and the least is invested. The different scenarios of
                    this are then graphed to help determine which course is
                    financially better.
                  </p>
                </div>
                <img src="loan.png" className="cardImg2"></img>
                <img
                  src="coinGarden.png"
                  className="cardImg2"
                  style={{ float: "right" }}
                ></img>
                <div className={payoffOver ? "hover" : "unhover"}>
                  <ChevronRightIcon fontSize="large"></ChevronRightIcon>
                  <div>Calculate </div>
                </div>
              </div>
            </div>
            <div
              className="info"
              onMouseOver={() => {
                setGoalsOver(true);
              }}
              onMouseOut={() => {
                setGoalsOver(false);
              }}
            >
              <div
                className="card insideCard"
                onClick={() => setRedirect("Goals")}
              >
                <div>
                  <h1>Goals Planner</h1>
                  <p>
                    Goals planner lets you set a goal then takes into
                    consideration a multitude of factors to give you a picture
                    of what you financial future could look like by the year. It
                    lets you set factors such as debts, house purchases, salary
                    increases and big expected purchases to give you a breakdown
                    of your finances and to show you areas you can improve.
                  </p>
                </div>
                <img src="GoalsPlanner.png" className="cardImg3"></img>
                <div className={goalsOver ? "hover" : "unhover"}>
                  <ChevronRightIcon fontSize="large"></ChevronRightIcon>
                  <div>Calculate </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Tailer />
      </div>
    );
  }
};

export default Info;
