import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import Checkbox from "@material-ui/core/Checkbox";
import Timeline from "@material-ui/lab/Timeline";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineDot from "@material-ui/lab/TimelineDot";
import TimelineOppositeContent from "@material-ui/lab/TimelineOppositeContent";
import { Icon, InlineIcon } from "@iconify/react";
import questionCircleOutlined from "@iconify-icons/ant-design/question-circle-outlined";

class Purchase extends React.Component {
  constructor(props) {
    super();
    this.purchaseChange = props.onChange;
    this.state = {
      order: ["life", "base", "purchase", "risk"],
      purchases: props.value.purchases,
      houses: props.value.houses,
    };
  }

  removePurchase = (index) => {
    var purchases = this.state.purchases;
    purchases.splice(index, 1);
    this.purchaseChange("purchases", purchases);
    this.setState({ purchases: purchases });
  };

  removeHouses = (index) => {
    var houses = this.state.houses;
    houses.splice(index, 1);
    this.purchaseChange("houses", houses);
    this.setState({ houses: houses });
  };

  addPurchase = () => {
    var purchases = this.state.purchases;
    purchases.push({ amount: "0", years: "0" });
    this.purchaseChange("purchases", purchases);
    this.setState({ purchases: purchases });
  };

  addHouse = () => {
    var houses = this.state.houses;
    houses.push({
      value: "0",
      down: "0",
      interestRate: "0.0",
      monthlyCost: "0",
      appreciationRate: "4.0",
      yearsAway: "3",
    });
    this.purchaseChange("houses", houses);
    this.setState({ houses: houses });
  };

  changePurchase = (value, id, isDebt) => {
    var purchases = this.state.purchases;
    purchases[id] = isDebt
      ? { amount: value, years: purchases[id].rate }
      : { amount: purchases[id].amount, years: value };

    this.purchaseChange("purchases", purchases);
    this.setState({ purchases: purchases });
  };

  changeHouses = (value, id, changeProp) => {
    var houses = this.state.houses;
    houses[id][changeProp] = value;
    console.log(houses);
    this.purchaseChange("houses", houses);
    this.setState({ houses: houses });
  };

  componentWillReceiveProps(props) {
    this.setState({
      hasHome: props.value.hasHome,
      assets: props.value.assets,
      roi: props.value.roi,
      debts: props.value.debts,
    });
  }

  render() {
    return (
      <div>
        <div className="debtsGrid">
          <div className="header"> Big Purchases </div>
          <button
            className="makeAPlanButton goalsPlusButtons plusDebtSize"
            onClick={this.addPurchase}
          >
            +
          </button>
        </div>
        <div>Cost | Years Away</div>
        <div>
          {this.state.purchases.map((purchases, i) => (
            <div className="debtsBox card" id={i}>
              <div>$</div>
              <input
                className="debtBoxDebt"
                value={purchases.amount}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9]/g, "");
                  num = num.replace(/^0+/, "");
                  this.changePurchase(
                    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                    i,
                    true
                  );
                }}
              ></input>
              <input
                className="debtBoxDebtPercent"
                value={purchases.years}
                onChange={(event) => {
                  var num = event.target.value.replace(/[^0-9.,]/g, "");
                  num = num.replace(/^0+/, "");
                  if (num.toString().length < 3) {
                    this.changePurchase(num.toString(), i, false);
                  }
                }}
              ></input>
              <button
                className="makeAPlanButton removeDebtsButton"
                onClick={() => {
                  this.removePurchase(i);
                }}
              >
                X
              </button>
            </div>
          ))}
          <div className="header"> House Purchase </div>
          <button
            className="makeAPlanButton goalsPlusButtons plusDebtSize"
            onClick={this.addHouse}
          >
            +
          </button>
          {this.state.houses.map((houses, i) => (
            <div className="houseBoxGrid houseBoxDebt card">
              <div className="houseBox" id={i}>
                <div className="flexChild">
                  <div>Home Value</div>$
                  <input
                    className="debtBoxDebt inputBoxWith"
                    value={houses.value}
                    onChange={(event) => {
                      var num = event.target.value.replace(/[^0-9]/g, "");
                      num = num.replace(/^0+/, "");
                      this.changeHouses(
                        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        i,
                        "value"
                      );
                    }}
                  ></input>
                </div>
                <div className="flexChild">
                  <div>Money Down</div>$
                  <input
                    className="debtBoxDebt inputBoxWith"
                    value={houses.down}
                    onChange={(event) => {
                      var num = event.target.value.replace(/[^0-9]/g, "");
                      num = num.replace(/^0+/, "");
                      this.changeHouses(
                        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        i,
                        "down"
                      );
                    }}
                  ></input>
                </div>
                <div className="flexChild">
                  <div>Loan Interest Rate</div>
                  <input
                    className="debtBoxDebt inputBoxWith"
                    value={houses.interestRate}
                    onChange={(event) => {
                      var num = event.target.value.replace(/[^0-9.]/g, "");
                      console.log(num);
                      num = num
                        .replace(/^0+/, "")
                        .replace(/\./, "#")
                        .replace(/\./g, "")
                        .replace(/#/, ".");
                      this.changeHouses(num.toString(), i, "interestRate");
                    }}
                  ></input>
                  %
                </div>
                <div className="flexChild">
                  <div>Monthly Cost</div>$
                  <input
                    className="debtBoxDebt inputBoxWith"
                    value={houses.monthlyCost}
                    onChange={(event) => {
                      var num = event.target.value.replace(/[^0-9]/g, "");
                      num = num.replace(/^0+/, "");
                      this.changeHouses(
                        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        i,
                        "monthlyCost"
                      );
                    }}
                  ></input>
                </div>
                <div className="flexChild">
                  <div>Home Appreciation Rate</div>
                  <input
                    className="debtBoxDebt inputBoxWith"
                    value={houses.appreciationRate}
                    onChange={(event) => {
                      var num = event.target.value.replace(/[^0-9.]/g, "");
                      num = num
                        .replace(/^0+/, "")
                        .replace(/\./, "#")
                        .replace(/\./g, "")
                        .replace(/#/, ".");
                      this.changeHouses(num.toString(), i, "appreciationRate");
                    }}
                  ></input>
                  %
                </div>
                <div className="flexChild">
                  <div>Years Away</div>
                  <input
                    className="debtBoxDebt inputBoxWith"
                    value={houses.yearsAway}
                    onChange={(event) => {
                      var num = event.target.value.replace(/[^0-9]/g, "");
                      num = num.replace(/^0+/, "");
                      this.changeHouses(
                        num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        i,
                        "yearsAway"
                      );
                    }}
                  ></input>
                </div>
              </div>

              <button
                className="makeAPlanButton removeDebtsButton"
                onClick={() => {
                  this.removeHouses(i);
                }}
              >
                X
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Purchase;
