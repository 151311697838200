import React, { useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import { Line } from "react-chartjs-2";
import ReactTooltip from "react-tooltip";
import { Redirect, NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { Drawer, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Hamburger from "hamburger-react";

const useStyles = makeStyles({
  drawer: {
    background: "rgb(77, 77, 99)",
    color: "rgb(77, 77, 99)",
  },
});
function Header(props) {
  console.log(props);
  const styles = useStyles();
  const [redirect, setRedirect] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [windowDimension, setWindowDimension] = useState(null);

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const isMobile = windowDimension <= 740;
  console.log(redirect);
  if (redirect !== "") {
    return <Redirect to={`/${redirect}`} />;
  } else {
    return (
      <div className="header_bar">
        {isMobile ? (
          <div>
            <Button
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <Hamburger
                toggled={drawerOpen}
                color="#FFFFFF"
                size={24}
                className=""
              ></Hamburger>
            </Button>
            <Drawer
              classes={{ paper: styles.drawer }}
              anchor={"right"}
              open={drawerOpen}
              containerStyle={{ backgroundColor: "black" }}
              onClose={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              <NavLink
                className="header_button"
                to="/info"
                exact
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Info
              </NavLink>
              <NavLink
                className="header_button"
                to="/home"
                exact
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Rent Vs. Buy
              </NavLink>
              <NavLink
                className="header_button"
                to="/compound"
                exact
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Compound Interest
              </NavLink>
              <NavLink
                className="header_button"
                to="/loan"
                exact
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Payoff Loan or Invest
              </NavLink>
              <NavLink
                className="header_button"
                to="/Goals"
                exact
                onClick={() => {
                  setDrawerOpen(!drawerOpen);
                }}
              >
                Financial Goals
              </NavLink>
            </Drawer>
          </div>
        ) : (
          <div>
            <NavLink
              className={
                "header_tag" +
                (props.highlight === "info" ? " bottomHighlight" : "")
              }
              to="/info"
              exact
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              Info
            </NavLink>
            <NavLink
              className={
                "header_tag" +
                (props.highlight === "home" ? " bottomHighlight" : "")
              }
              to="/home"
              exact
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              Rent Vs. Buy
            </NavLink>
            <NavLink
              className={
                "header_tag" +
                (props.highlight === "compound" ? " bottomHighlight" : "")
              }
              to="/compound"
              exact
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              Compound Interest
            </NavLink>
            <NavLink
              className={
                "header_tag" +
                (props.highlight === "loan" ? " bottomHighlight" : "")
              }
              to="/loan"
              exact
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              Payoff Loan or Invest
            </NavLink>
            <NavLink
              className={
                "header_tag" +
                (props.highlight === "goals" ? " bottomHighlight" : "")
              }
              to="/Goals"
              exact
              onClick={() => {
                setDrawerOpen(!drawerOpen);
              }}
            >
              Financial Goals
            </NavLink>
          </div>
        )}
      </div>
    );
  }
}

export default Header;
